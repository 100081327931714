import _ from "lodash"
import Vue from "vue"
import Router, { Route } from "vue-router"
import Cookies from "js-cookie"

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "root",
      redirect: "/home",
    },
    {
      path: "/login/callback",
      name: "login-callback",
      component: () => import("@/views/LoginCallback.vue"),
    },
    {
      path: "/home",
      name: "home",
      redirect: "/return",
      component: () => import("@/views/Home.vue"),
      meta: {
        titleKey: "route.home",
        titleRoute: "home",
      },
    },
    {
      path: "/deployment",
      name: "deployment",
      redirect: "/return",
      component: () => import("@/views/Deployment.vue"),
      meta: {
        titleKey: "route.deployment",
        titleRoute: "deployment",
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      redirect: "/return",
      component: () => import("@/views/Dashboard.vue"),
      meta: {
        titleKey: "route.dashboard",
        titleRoute: "dashboard",
      },
    },
    {
      path: "/support",
      name: "support",
      redirect: "/return",
      component: () => import("@/views/Support.vue"),
      meta: {
        titleKey: "route.support",
        titleRoute: "support",
      },
    },
    {
      path: "/order",
      name: "order",
      redirect: "/return",
      component: () => import("@/views/Order.vue"),
      meta: {
        titleKey: "route.order",
        titleRoute: "order",
      },
    },
    {
      path: "/return",
      name: "return",
      component: () => import("@/views/Return.vue"),
      meta: {
        titleKey: "route.return",
        titleRoute: "return",
      },
      children: [
        {
          path: "new",
          name: "return-new",
          meta: {
            titleKey: "route.return",
            titleRoute: "return",
          },
        },
      ],
    },
    {
      path: "/return/:id",
      name: "return-info",
      component: () => import("@/views/ReturnInfo.vue"),
      meta: {
        titleKey: "route.return",
        titleRoute: "return",
      },
    },
    {
      path: "/projects",
      name: "statustracker",
      component: () => import("@/views/StatusTracker.vue"),
      meta: {
        titleKey: "route.statustracker",
        titleRoute: "statustracker",
      },
    },
    {
      path: "/projects/:referenceNumber",
      name: "statustracker-details",
      component: () => import("@/views/StatusTrackerDetails.vue"),
      meta: {
        titleKey: "route.statustracker",
        titleRoute: "statustracker",
      },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
})

router.onError((error: Error) => {
  router.app.$raven.captureException(error)
  router.app.$raven.showReportDialog()
})

router.beforeEach(async (to: Route, from: Route, next: Function) => {
  if (to.name === "login-callback") {
    const redirectTo = Cookies.get("redirectTo") || null
    if (_.isString(redirectTo)) {
      Cookies.remove("redirectTo")
      to.query.next = redirectTo
    }
    if (_.isNil(to.hash) || _.isEmpty(to.hash)) {
      next({ name: "home" })
    } else {
      next()
    }
  } else {
    if (await router.app.$auth.checkAuthentication()) {
      if (!_.has(router.app.$raven.getContext(), "user")) {
        router.app.$raven.setUserContext({
          email: _.get(router.app.$auth.user, "email", "Unknown Email"),
          id: _.get(router.app.$auth.user, "user_id", "Unknown Id"),
          name: _.get(router.app.$auth.user, "name", "Unknown User"),
        })
      }
      // @ts-ignore
      router.app.$keen.extendEvents({
        user: router.app.$auth.user,
        environment: process.env.NODE_ENV,
      })
      next()
    } else {
      try {
        await router.app.$auth.checkSession()
        next()
      } catch (err) {
        Cookies.set("redirectTo", to.path)
        await router.app.$auth.login()
      }
    }
  }
})

export default router
