import _ from "lodash"
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators"
import { cx } from "@/types"
import { IUserState } from "./types"
import { Auth0UserProfile } from "auth0-js"
import { StorageService } from "@/services/storage"
import auth from "@/plugins/auth0"
import AppService from "@/services/app"

const storageService = new StorageService()
const appService = new AppService()
// NOTE: the preferences key is used for namespacing platform preferences in auth0
const preferencesKey: string = "cx-sensus-com"
const defaultPreferences: cx.App.UserPreferences = {
  returns: {
    enable_customer_tag: false,
    enable_account_number: false,
  },
}

/**
 * The user store module.
 *
 * @export
 * @class UserModule
 * @extends {VuexModule<IUserState>}
 */
@Module({ namespaced: true })
export default class UserModule extends VuexModule<IUserState> {
  // The user profile information
  user: Auth0UserProfile | null = null
  // The user preferences object
  preferences: cx.App.UserPreferences = defaultPreferences

  /**
   * Get the current user profile.
   *
   * @readonly
   * @type {(Auth0UserProfile | null)}
   * @memberof UserModule
   */
  get getUser(): Auth0UserProfile | null {
    return this.user
  }

  /**
   * Get a flag that checks if the user is an employee.
   *
   * @readonly
   * @type {boolean}
   * @memberof UserModule
   */
  get isEmployee(): boolean {
    return (
      !_.isNil(this.user) && _.includes(["salesforce", "salesforce-sandbox"], _.split(this.user.user_id, "|", 1)[0])
    )
  }

  /**
   * Check if the user has defined preferences.
   *
   * @readonly
   * @type {boolean}
   * @memberof UserModule
   */
  get hasPreferences(): boolean {
    return (
      !_.isNil(this.user) &&
      _.has(this.user.user_metadata, preferencesKey) &&
      !_.isEmpty(_.get(this.user.user_metadata, preferencesKey, {}))
    )
  }

  /**
   * Get the user's prefrences.
   *
   * @readonly
   * @type {cx.App.UserPreferences}
   * @memberof UserModule
   */
  get getPreferences(): cx.App.UserPreferences {
    return _.get(this.user, ["user_metadata", preferencesKey], _.clone(defaultPreferences))
  }

  /**
   * Set the current user in the store.
   *
   * @param {Auth0UserProfile} user The current user profile
   * @memberof UserModule
   */
  @Mutation
  setUser(user: Auth0UserProfile) {
    this.user = _.clone(user)
  }

  /**
   * Clear the current user in the store.
   *
   * @memberof UserModule
   */
  @Mutation
  clearUser() {
    this.user = null
  }

  /**
   * Reset the current user's preferences to defaults.
   *
   * @memberof UserModule
   */
  @Action
  async resetPreferences() {
    if (await auth.checkAuthentication()) {
      const userMetadata = { [preferencesKey]: defaultPreferences }
      const userInfo = await auth.updateUserMetadata(userMetadata)
      storageService.set("user", JSON.stringify(userInfo))
      this.context.commit("setUser", userInfo)
    }
  }

  /**w
   * Set the current user's preferences.
   *
   * @param {cx.App.UserPreferences} preferences The new preferences instance
   * @memberof UserModule
   */
  @Action
  async savePreferences(preferences: cx.App.UserPreferences) {
    if (await auth.checkAuthentication()) {
      const userMetadata = { [preferencesKey]: preferences }
      const userInfo = await auth.updateUserMetadata(userMetadata)
      storageService.set("user", JSON.stringify(userInfo))
      this.context.commit("setUser", userInfo)
    }
  }

  /**
   * Clear the app state for the current user.
   *
   * @memberof UserModule
   */
  @Action
  async clearState() {
    this.context.commit("returns/clearWorkingReturn", null, { root: true })
    const user: Auth0UserProfile | null = this.context.getters["getUser"]
    if (!_.isNil(user)) {
      const key = user.user_id
      if (_.isString(key)) {
        appService.updateAppState(key, JSON.stringify({ workingReturn: null }))
      }
    }
  }

  /**
   * Restore the client side app state.
   *
   * @memberof UserModule
   */
  @Action
  async restoreState() {
    const user: Auth0UserProfile | null = this.context.getters["getUser"]
    if (!_.isNil(user)) {
      const key = user.user_id
      const appState = await appService.getAppState(key)
      let storedState = appState ? appState.state : null
      if (!_.isString(storedState)) {
        this.context.commit("returns/updateWorkingReturn", null, { root: true })
        this.context.dispatch("saveState")
      } else {
        storedState = JSON.parse(storedState)
        this.context.commit("returns/updateWorkingReturn", storedState.workingReturn, { root: true })
      }
    }
  }

  /**
   * Save the current client side app state.
   *
   * @memberof UserModule
   */
  @Action
  async saveState() {
    const user: Auth0UserProfile | null = this.context.getters["getUser"]
    if (!_.isNil(user)) {
      const key = user.user_id
      if (_.isString(key)) {
        const currentState = await appService.getAppState(key)
        const workingReturn = this.context.rootGetters["returns/getWorkingReturn"]
        const reducedState = { workingReturn }
        const stringifiedState = JSON.stringify(reducedState)
        if (!_.isNil(currentState) && !_.isEmpty(currentState)) {
          if (!_.isEqual(currentState, stringifiedState)) {
            appService.updateAppState(key, stringifiedState)
          }
        } else {
          appService.createAppState(key, stringifiedState)
        }
      }
    }
  }
}
