import _ from "lodash"
import { Module, VuexModule, Mutation } from "vuex-module-decorators"
import { IStatusTrackerState } from "./types"
import { cx } from "@/types"

/**
 * The status tracker application store module.
 *
 * @export
 * @class StatusTrackerModule
 * @extends {VuexModule<IStatusTrackerState>}
 */
@Module({ namespaced: true })
export default class StatusTrackerModule extends VuexModule<IStatusTrackerState> {
  // status tracker project filters
  filters: cx.StatusTracker.IFilters = {
    sensusName: "",
    accountName: "",
    varName: "",
    type: "",
    referenceNumber: "",
    state: [],
  }

  /**
   * Get the current filters.
   *
   * @readonly
   * @memberof StatusTrackerModule
   */
  get getFilters() {
    return this.filters
  }

  /**
   * Set the filters.
   *
   * @param {cx.StatusTracker.IFilters} filters The new filters
   * @memberof StatusTrackerModule
   */
  @Mutation
  setFilters(filters: cx.StatusTracker.IFilters) {
    this.filters = _.clone(filters)
  }

  /**
   * Update the filters.
   *
   * @param {cx.StatusTracker.IFilters} filters The partial filters to merge
   * @memberof StatusTrackerModule
   */
  @Mutation
  updateFilters(filters: cx.StatusTracker.IFilters) {
    this.filters = _.assign(this.filters, filters)
  }
}
