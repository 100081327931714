import Vue from "vue"
import VueKeen from "vue-keen-tracking"
import router from "@/router"
import store from "@/store"

const keenOptions = {
  projectId: process.env.VUE_APP_KEEN_PROJECT_ID,
  writeKey: process.env.VUE_APP_KEEN_WRITE_KEY,
  autoTracking: true,
  trackRoutes: {
    router: router,
  },
  trackVuex: {
    store: store,
    mutations: true,
    actions: true,
  },
}

Vue.use(VueKeen, keenOptions)
