import _ from "lodash"
import { Module, VuexModule, Mutation } from "vuex-module-decorators"
import { IReturnsState } from "./types"
import { cx } from "@/types"

/**
 * The returns application store module.
 *
 * @export
 * @class ReturnsModule
 * @extends {VuexModule<IReturnsState>}
 */
@Module({ namespaced: true })
export default class ReturnsModule extends VuexModule<IReturnsState> {
  // The current working return state
  workingReturn: cx.Returns.Return | null = null

  /**
   * Check if the working return state is set.
   *
   * @readonly
   * @type {boolean}
   * @memberof ReturnsModule
   */
  get hasWorkingReturn(): boolean {
    return !_.isNil(this.workingReturn) && !_.isEmpty(this.workingReturn)
  }

  /**
   * Get the current working return.
   *
   * @readonly
   * @type {(IReturnsWorkingReturn | null)}
   * @memberof ReturnsModule
   */
  get getWorkingReturn(): cx.Returns.Return | null {
    return this.workingReturn
  }

  /**
   * Fully set the current working return state.
   *
   * @param {IReturnsWorkingReturn} workingReturn The working return
   * @memberof ReturnsModule
   */
  @Mutation
  setWorkingReturn(workingReturn: cx.Returns.Return) {
    this.workingReturn = _.clone(workingReturn)
  }

  /**
   * Update the current working return state.
   *
   * @param {object} payload The partial working return object to merge
   * @memberof ReturnsModule
   */
  @Mutation
  updateWorkingReturn(payload: object) {
    this.workingReturn = {
      ...this.workingReturn,
      ...payload,
    } as cx.Returns.Return
  }

  /**
   * Clear the current working return state.
   *
   * @memberof ReturnsModule
   */
  @Mutation
  clearWorkingReturn() {
    this.workingReturn = null
  }
}
