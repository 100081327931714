













































































































































































































import { Vue, Component, Watch } from "vue-property-decorator"
import { Getter } from "vuex-class"
import _ from "lodash"
import sanitizeHtml from "sanitize-html"
import loading from "vue-full-loading"
import s3 from "@/services/s3"
import { Auth0UserProfile } from "auth0-js"
import Trunquee from "@/components/Trunquee.vue"

type Locale = {
  name: string
  locale: string
}
type Documentation = {
  name: string
  icon: string
  route: string
  items: Array<Item>
}
type Item = {
  name: string
  description: string
  icon: string
  download: boolean
  url: string
}
type Route = {
  name: string
  path: string
  icon: string
}

@Component({
  name: "App",
  components: {
    loading: loading,
    Trunquee,
  },
  i18n: {
    messages: {
      en: {
        portalLinkText: "Return to My Sensus",
        helpDocumentationText: "Help",
      },
      es: {
        portalLinkText: "Volver a My Sensus",
        helpDocumentationText: "Ayuda",
      },
      ja: {
        portalLinkText: "My Sensusに戻る",
        helpDocumentationText: "助けて",
      },
    },
  },
})
export default class App extends Vue {
  private readonly s3Service = new s3()
  loadingText: string = "Loading..."
  snackbar: any = {
    active: false,
  }
  appDropdown: boolean = false
  accountDropdown: boolean = false
  missingAccountImage: boolean = false
  allowAppSwitch: boolean = true
  allowLocaleSwitch: boolean = false
  localeDialog: boolean = false
  locale: string = "en"
  locales: Array<Locale> = [
    { name: "English", locale: "en" },
    { name: "Español", locale: "es" },
    { name: "日本語", locale: "ja" },
  ]
  helpDocumentationDialog: boolean = false
  helpDocumentationItems: Array<Documentation> = [
    {
      name: "Returns",
      icon: "mdi-truck",
      route: "return",
      items: [
        {
          name: "Return User Guide.pdf",
          description: "A user guide on how to use the returns application",
          icon: "insert_drive_file",
          download: true,
          url: "/public/docs/return-app-user-guide.pdf",
        },
        {
          name: "Return Tutorial Video",
          description: "A quick tutorial on how to create a return",
          icon: "movie",
          download: false,
          url: "https://vimeo.com/287133615/d81eedd4b7",
        },
        {
          name: "Return Interactive Tutorial",
          description: "An interactive tutorial on how to create a return",
          icon: "widgets",
          download: false,
          url: "https://www.sensus-training.com/RMA/Rise/index.html",
        },
      ],
    },
    {
      name: "Status Tracker",
      icon: "mdi-library-books",
      route: "statustracker",
      items: [
        {
          name: "Status Tracker User Guide.pdf",
          description: "A user guide on how to use the status tracker application",
          icon: "insert_drive_file",
          download: true,
          url: "/public/docs/status-tracker-user-guide.pdf",
        },
      ],
    },
  ]
  appRoutes: Array<Route> = [
    {
      name: "route.return",
      path: "/return",
      icon: "mdi-truck",
    },
    {
      name: "route.statustracker",
      path: "/projects",
      icon: "mdi-library-books",
    },
  ]
  stateInterval: any = null //we can use window.setInterval but it might not be worth the hassle to type this
  stateRestored: boolean = false

  @Getter("user/getUser")
  user!: Auth0UserProfile | null

  @Watch("locale")
  changeLocale(value: string) {
    this.$i18n.locale = value
  }

  @Watch("stateRestored")
  clearStateInterval(value: boolean) {
    if (value) clearInterval(this.stateInterval)
  }

  logout() {
    this.$events.emit("show-loading", "Logging out...")
    this.$auth.logout()
    this.$raven.setUserContext()
  }

  handleHelpDocumentationItem(helpDocumentationItem: Item) {
    if (_.get(helpDocumentationItem, "download", false)) {
      this.s3Service.downloadObject(helpDocumentationItem.url, helpDocumentationItem.name).catch(() => {
        this.$events.emit("show-snackbar", {
          text: `Failed to download ${helpDocumentationItem.name}`,
          color: "error",
        })
      })
    } else {
      window.open(helpDocumentationItem.url, "_blank")
    }
  }

  accountImageError() {
    this.missingAccountImage = true
  }

  sanitize(content: any) {
    return sanitizeHtml(content)
  }

  mounted() {
    this.stateInterval = setInterval(() => {
      if (!_.isNil(this.user) && _.isString(this.user.sub) && !this.stateRestored) {
        this.$store.dispatch("user/restoreState")
        this.stateRestored = true
      }
    }, 1000)
    this.$events.on("show-loading", text => {
      this.loadingText = text || "Loading..."
    })
    this.$events.on("show-snackbar", config => {
      let ensureShow = false
      if (_.isNil(config.active) || !config.active) {
        ensureShow = true
      }
      this.snackbar = _.assign(this.snackbar, config)
      if (ensureShow) {
        this.snackbar.active = true
      }
    })
  }
}
