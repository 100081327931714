import Vue from "vue"
import VueQuillEditor from "vue-quill-editor"

import "quill/dist/quill.core.css"
import "quill/dist/quill.bubble.css"
import "@/assets/styles/quill.scss"

Vue.use(VueQuillEditor, {
  theme: "snow",
  modules: {
    toolbar: false,
    clipboard: true,
  },
  placeholder: "Write a comment...",
})
