import _ from "lodash"
import Vue from "vue"
import Auth0, { Auth0UserProfile, Auth0Error, Auth0DecodedHash } from "auth0-js"
import Auth0Lock from "auth0-lock"

import store from "@/store"
import { StorageService } from "@/services/storage"
import { IAuthComponent } from "./types"

const { theme, neutral } = require("@/assets/styles/metry/helpers/js/colors")

// the client storage service instance
const storageService = new StorageService()

// required auth0 scope for our users
const requiredScopes = "openid profile email read:current_user update:current_user_metadata"

// the auth0 lock instance for our auth0 domain
const authLock = new Auth0Lock(process.env.VUE_APP_AUTH0_CLIENT_ID, process.env.VUE_APP_AUTH0_DOMAIN, {
  closable: true,
  autoclose: true,
  auth: {
    autoParseHash: false,
    redirectUrl: process.env.VUE_APP_AUTH0_CALLBACK_URL,
    responseType: "token id_token",
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    params: {
      scope: requiredScopes,
    },
    sso: false,
  },
  languageDictionary: {
    title: "Sensus CX",
    loginWithLabel: "Log in as %s",
  },
  leeway: 300,
  theme: {
    logo: "https://image.ibb.co/bxNRbK/favicon.png",
    primaryColor: theme.primary,
    authButtons: {
      "salesforce-community": {
        displayName: "Customer",
      },
      "salesforce-sandbox": {
        displayName: "Employee",
        primaryColor: neutral["300"],
      },
      salesforce: {
        displayName: "Employee",
        primaryColor: neutral["300"],
      },
    },
  },
})

/**
 * Build a Auth0 management instance.
 *
 * @param {String} token The token to connect to the management api with
 * @returns An instance of `Auth0.Management`
 */
const buildManagement = (token: string) => {
  return new Auth0.Management({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    token,
  })
}

/**
 * The auth management plugin.
 *
 * @class Auth
 * @implements {IAuthComponent}
 */
class Auth implements IAuthComponent {
  lock: Auth0LockStatic = authLock
  authenticated: boolean = false
  private _management: Auth0.Management | null = null

  /**
   * Get the current `idToken`.
   *
   * @memberof Auth
   */
  get idToken() {
    return storageService.get("idToken")
  }

  /**
   * Set the current `idToken`
   *
   * @param {(string|null)} token The new `idToken`
   * @memberof Auth
   */
  set idToken(token: string | null) {
    if (_.isString(token)) {
      storageService.set("idToken", token)
    }
  }

  /**
   * Get the current `accessToken`
   *
   * @memberof Auth
   */
  get accessToken() {
    return storageService.get("accessToken")
  }

  /**
   * Set the `accessToken`
   *
   * @param {(string|null)} token The new `accessToken`
   * @memberof Auth
   */
  set accessToken(token: string | null) {
    if (_.isString(token)) {
      storageService.set("accessToken", token)
    }
  }

  /**
   * Get the current `expiresAt` timestamp.
   *
   * @memberof Auth
   */
  get expiresAt() {
    const expiresAt = storageService.get("expiresAt")
    if (_.isString(expiresAt)) {
      return _.parseInt(expiresAt)
    }
    return null
  }

  /**
   * Set the `expiresAt` timestamp.
   *
   * @param {(string|null)} timestamp The new timestamp for `expiresAt`
   * @memberof Auth
   */
  set expiresAt(timestamp: number | null) {
    if (_.isNumber(timestamp)) {
      storageService.set("expiresAt", timestamp.toString())
    }
  }

  /**
   * Get the curernt user.
   *
   * @memberof Auth
   */
  get user() {
    const user = storageService.get("user")
    if (_.isString(user) && !_.isEmpty(user)) {
      try {
        const parsedUser: Auth0UserProfile = JSON.parse(user)
        if (_.isNil(store.state.user.user)) {
          store.commit("user/setUser", parsedUser)
        }
        return parsedUser
      } catch (err) {
        // if something fails when decoding the user, we can't safely assume who they are
        this.logout()
        throw err
      }
    }
    return null
  }

  /**
   * Set the current `user`.
   *
   * @param {(Auth0UserProfile|null)} user The new `user` instance
   * @memberof Auth
   */
  set user(user: Auth0UserProfile | null) {
    // only stores the user if the user is a valid object (not null or undefined)
    if (_.isObject(user)) {
      storageService.set("user", JSON.stringify(user))
      store.commit("user/setUser", user)
    }
  }

  /**
   * Get the current management api for the user.
   *
   * @readonly
   * @type {(Auth0.Management | null)}
   * @memberof Auth
   */
  get management(): Auth0.Management | null {
    if (_.isNil(this._management)) {
      if (!_.isNil(this.accessToken)) {
        const management = buildManagement(this.accessToken)
        this._management = management
        return management
      }
    } else {
      return this._management
    }
    return null
  }

  /**
   * Prompt the user to login.
   *
   * @memberof Auth
   */
  login() {
    this.lock.show()
  }

  /**
   * Remove authentication information from client and logout user.
   *
   * @memberof Auth
   */
  logout() {
    storageService.remove("accessToken")
    storageService.remove("idToken")
    storageService.remove("expiresAt")
    storageService.remove("user")
    store.commit("user/clearUser")
    this.authenticated = false
    this.lock.logout({
      returnTo: process.env.VUE_APP_AUTH0_LOGOUT_URL,
    })
  }

  /**
   * Get the current user profile.
   *
   * @param {(string|null)} token The token of the current user
   * @returns {Promise<Auth0UserProfile>} The current user's profile
   * @memberof Auth
   */
  async getUser(token: string | null): Promise<Auth0UserProfile> {
    if (!_.isNil(this.user) && !_.isEmpty(this.user) && !_.isNil(this.user.user_id)) {
      return this.user
    } else {
      const userPromise: Promise<Auth0UserProfile> = new Promise((resolve: Function, reject: Function) => {
        // get the desired user token from either the given token or the accessToken
        const userToken: string = _.isString(token)
          ? token
          : _.isString(this.accessToken)
          ? this.accessToken
          : reject(new Error("no valid user token exists"))

        this.lock.getUserInfo(userToken, async (err: Auth0Error, userInfo: object) => {
          if (_.isNil(err) && _.has(userInfo, "sub")) {
            // define user management if not defined
            let management = this._management
            if (_.isNil(management)) {
              this._management = buildManagement(userToken)
              management = this._management
            }
            management.getUser(_.get(userInfo, "sub"), (error: Auth0Error | null, profile: Auth0UserProfile) => {
              if (_.isNil(error)) {
                resolve(profile)
              } else {
                reject(error)
              }
            })
          } else {
            reject(err)
          }
        })
      })
      return await userPromise
    }
  }

  /**
   * Update current user's metadata in Auth0.
   *
   * @param {object} metadata The new metadata object
   * @returns The response of the management API call
   * @throws When the `Auth0.Management.patchUserMetadata` API call fails
   */
  async updateUserMetadata(metadata: object) {
    const user = await this.getUser(this.accessToken)
    const userProfile: Auth0UserProfile | null = await new Promise((resolve, reject) => {
      if (!_.isNil(this.management)) {
        this.management.patchUserMetadata(
          user.user_id,
          metadata,
          (error: Auth0Error | null, profile: Auth0UserProfile | null) => {
            if (_.isNil(error)) {
              resolve(profile)
            } else {
              reject(error)
            }
          }
        )
      } else {
        reject("unable to update user metadata, management is not defined")
      }
    })
    return userProfile
  }

  /**
   * Checks if the current user is properly authenticated.
   *
   * @returns True if authenticated, otherwise False
   * @memberof Auth
   */
  async checkAuthentication() {
    this.authenticated =
      !_.isNil(this.accessToken) &&
      !_.isNil(this.idToken) &&
      !_.isNil(this.expiresAt) &&
      this.expiresAt >= new Date().getTime()

    if (this.authenticated) {
      if (
        _.isNil(store.state.user.user) ||
        _.isEmpty(store.state.user.user) ||
        _.isNil(store.state.user.user.user_id)
      ) {
        // update client storage with user information from the state if it exists
        this.user = await this.getUser(this.accessToken)
      }
    }
    return this.authenticated
  }

  /**
   * Parse the given decoded auth result and handle setting requried application attributes.
   *
   * @param {Auth0DecodedHash} authResult
   * @returns
   * @memberof Auth
   */
  async handleAuthResult(authResult: Auth0DecodedHash) {
    this.accessToken = _.get(authResult, "accessToken", null)
    this.idToken = _.get(authResult, "idToken", null)
    this.expiresAt = authResult.expiresIn ? authResult.expiresIn * 1000 + new Date().getTime() : null
    this.user = await this.getUser(this.accessToken)
    this.authenticated = true
    return this.user
  }

  /**
   * Decode and parse the given auth hash and handle setting required application attributes.
   *
   * @param {string} authHash
   * @memberof Auth
   */
  async handleAuthHash(authHash: string) {
    const userProfile: Auth0UserProfile = await new Promise((resolve, reject) => {
      this.lock.resumeAuth(authHash, async (error: Auth0Error, authResult: Auth0DecodedHash) => {
        if (_.isNil(error)) {
          const userProfile = await this.handleAuthResult(authResult)
          resolve(userProfile)
        } else {
          reject(error)
        }
      })
    })
    return userProfile
  }

  /**
   * Check the current user's Auth0 authentication session and update the auth result if necessary.
   *
   * @memberof Auth
   */
  async checkSession() {
    const userProfile: Auth0UserProfile = await new Promise((resolve, reject) => {
      this.lock.checkSession(
        {
          audience: process.env.VUE_APP_AUTH0_AUDIENCE,
          scope: requiredScopes,
        },
        async (error?: Auth0Error, authResult?: Auth0DecodedHash) => {
          if (_.isNil(error) && _.isObject(authResult)) {
            const userProfile = await this.handleAuthResult(authResult)
            resolve(userProfile)
          } else {
            reject(error)
          }
        }
      )
    })
    return userProfile
  }
}

const auth: IAuthComponent = new Auth()
export default auth

Vue.use({
  install: function (Vue) {
    Vue.prototype.$auth = auth
  },
})
