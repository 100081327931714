import pluralize from "pluralize"
import Vue, { VueConstructor } from "vue"
import Vue2Filters from "vue2-filters"
import VueNumeralFilter from "vue-numeral-filter"

Vue.use(Vue2Filters)
Vue.use(VueNumeralFilter)
Vue.use({
  install: function (vue: VueConstructor<Vue>, options?: any) {
    vue.filter("pluralize", (value: number, text: string, showCount: boolean = false): string => {
      if (!value) return ""
      return pluralize(text, value, showCount)
    })
    vue.filter("phone", (phone: string): string => {
      return phone.replace(/(\d{3})[^\d]*(\d{3})[^\d]*(\d{4})/, "($1) $2-$3")
    })
    vue.filter("lowercase", (value: string): string => {
      return value.toLowerCase()
    })
    vue.filter("titlecase", (value: string, lowercase: boolean = false): string => {
      // NOTE: taken from <https://github.com/gouch/to-title-case>
      const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
      const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
      const wordSeparators = /([ :–—-])/

      if (lowercase) {
        value = value.toLowerCase()
      }

      return value
        .split(wordSeparators)
        .map(function (current, index, array) {
          if (
            /* Check for small words */
            current.search(smallWords) > -1 &&
            /* Skip first and last word */
            index !== 0 &&
            index !== array.length - 1 &&
            /* Ignore title end and subtitle start */
            array[index - 3] !== ":" &&
            array[index + 1] !== ":" &&
            /* Ignore small words that start a hyphenated phrase */
            (array[index + 1] !== "-" || (array[index - 1] === "-" && array[index + 1] === "-"))
          ) {
            return current.toLowerCase()
          }

          /* Ignore intentional capitalization */
          if (current.substr(1).search(/[A-Z]|\../) > -1) {
            return current
          }

          /* Ignore URLs */
          if (array[index + 1] === ":" && array[index + 2] !== "") {
            return current
          }

          /* Capitalize the first letter */
          return current.replace(alphanumericPattern, function (match) {
            return match.toUpperCase()
          })
        })
        .join("")
    })
  },
})
