import _ from "lodash"
import Vue from "vue"
import VueApollo, { ApolloProvider } from "vue-apollo"
import { StorageService } from "@/services/storage"
import ApolloClient from "apollo-client"

const { createApolloClient, restartWebsockets } = require("vue-cli-plugin-apollo/graphql-client")

// Install the vue plugin
Vue.use(VueApollo)

const storageService = new StorageService()
// Name of the localStorage item
const AUTH_TOKEN = "idToken"

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: process.env.VUE_APP_GRAPHQL_HTTP || "http://localhost:5000/graphql",
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  // websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default http link
  // link: myLink

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  getAuth: (tokenName: string) => {
    if (storageService.has(tokenName)) {
      return `Bearer ${storageService.get(tokenName)}`
    }
  },

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider(options: object = {}): ApolloProvider {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error: Error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      )
    },
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient: ApolloClient<any>, token: string) {
  storageService.set(AUTH_TOKEN, token)
  if (_.has(apolloClient, "wsClient")) {
    const wsClient = _.get(apolloClient, "wsClient")
    if (!_.isNil(wsClient)) {
      restartWebsockets(wsClient)
    }
  }

  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient: ApolloClient<any>) {
  storageService.remove(AUTH_TOKEN)
  if (_.has(apolloClient, "wsClient")) {
    const wsClient = _.get(apolloClient, "wsClient")
    if (!_.isNil(wsClient)) {
      restartWebsockets(wsClient)
    }
  }

  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message)
  }
}
