import _ from "lodash"
import moment from "moment"
import { ApolloClient, ApolloQueryResult } from "apollo-client"
import { FetchResult } from "apollo-link"
import { DocumentNode } from "graphql"

import { cx } from "@/types"
import * as schema from "@/graphql/__schema"
import { createProvider } from "@/plugins/apollo"
import AppUtility from "./utility"

import attachmentMutation from "@/graphql/create-attachment.gql"
import supportCaseAttachmentMappingMutation from "@/graphql/create-support-case-attachment-mapping.gql"
import engagementAttachmentMappingMutation from "@/graphql/create-engagement-attachment-mapping.gql"
import createCaseCommentMutation from "@/graphql/create-case-comment.gql"

//private type to only be referenced within the App class
type Note = {
  nodeId: string
  id: number
}

const queries: {
  [key: string]: {
    [key: string]: Promise<DocumentNode>
  }
} = {
  state: {
    allStates: import("@/graphql/get-all-states.gql"),
  },
  workflow: {
    getWorkflowGroup: import("@/graphql/get-workflow-group.gql"),
  },
  user: {
    getUserBySalesforceId: import("@/graphql/get-user-by-salesforce-id.gql"),
  },
  app: {
    getAppState: import("@/graphql/get-app-state.gql"),
  },
}

const mutations: {
  [key: string]: {
    [key: string]: Promise<DocumentNode>
  }
} = {
  note: {
    createNote: import("@/graphql/create-note.gql"),
    deleteNote: import("@/graphql/delete-note.gql"),
  },
  app: {
    createAppState: import("@/graphql/create-app-state.gql"),
    updateAppState: import("@/graphql/update-app-state.gql"),
  },
}

export default class CX {
  private readonly apollo: ApolloClient<any>

  constructor() {
    this.apollo = createProvider().defaultClient
  }

  async getAppState(userId: string): Promise<cx.App.AppState | null> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: { userId },
      fetchPolicy: "network-only",
      query: await queries.app.getAppState,
    })
    return response.data.appStateByUserId && response.data.appStateByUserId.state
  }

  async createAppState(userId: string, state: string): Promise<cx.App.AppState> {
    const response: FetchResult<schema.Mutation> = await this.apollo.mutate({
      variables: { userId, state },
      mutation: await mutations.app.createAppState,
    })
    return response.data!.createAppState!.appState as cx.App.AppState
  }

  async updateAppState(userId: string, state: string): Promise<cx.App.AppState> {
    const response: FetchResult<schema.Mutation> = await this.apollo.mutate({
      variables: { userId, state, datetime: moment().toISOString() },
      mutation: await mutations.app.updateAppState,
    })
    return response.data!.updateAppStateByUserId!.appState as cx.App.AppState
  }

  async getAllStates(): Promise<Array<cx.App.State>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.state.allStates,
    })
    return _.map(response.data.allStates!.nodes, state => {
      return AppUtility.BUILD_STATE(state)
    })
  }

  async getWorkflowGroup(groupName: string): Promise<Array<cx.App.Workflow>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.workflow.getWorkflowGroup,
      variables: {
        groupName,
      },
    })
    return _.map(response.data.allWorkflows!.nodes, workflow => {
      return AppUtility.BUILD_WORKFLOW(workflow)
    })
  }

  async getUserBySalesforceId(userSalesforceId: string): Promise<cx.App.User> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.user.getUserBySalesforceId,
      variables: { salesforceId: userSalesforceId },
    })

    return AppUtility.BUILD_USER(response.data.userBySalesforceId!)
  }

  async createNote(
    noteSalesforceId: string,
    userId: number,
    title: string,
    content: string,
    isPrivate: boolean
  ): Promise<Note> {
    const response: FetchResult<schema.Mutation> = await this.apollo.mutate({
      mutation: await mutations.note.createNote,
      variables: { salesforceId: noteSalesforceId, userId: userId, title, body: content, isPrivate },
    })
    const note = response.data!.createNote!.note!
    return {
      nodeId: note.nodeId,
      id: note.id,
    }
  }

  async deleteNote(nodeId: string): Promise<string> {
    const response: FetchResult<schema.Mutation> = await this.apollo.mutate({
      mutation: await mutations.note.deleteNote,
      variables: {
        nodeId: nodeId,
      },
    })
    return response.data!.deleteNote!.deletedNoteId!
  }

  /**
   *
   * Creates a support case attachment in the database.
   *   Inserts into the attachment table then inserts into the support_case_attachment_map table
   *
   * @param {number} supportCaseId - support_case id field (postgres key)
   * @param {string} salesforceId - case id from salesforce
   * @param {string} s3Key - key for s3 item storage (ie. 'attachment/salesforce/XYZ123')
   * @param {string} name  - file name including extension (for saving later)
   * @param {boolean} active - active attachment or not
   */
  public createSupportCaseAttachment(
    supportCaseId: number,
    salesforceId: string,
    s3Key: string,
    name: string,
    active: boolean = true
  ) {
    return this.createAttachment(salesforceId, s3Key, name, active).then(response =>
      this.createSupportCaseAttachmentMapping(response.data.createAttachment.attachment.id, supportCaseId, active)
    )
  }

  /**
   *
   * Creates an engagement attachment in the database.
   *   Inserts into the attachment table then inserts into the engagement_attachment_map table
   *
   * @param {number} engagementId - engagement id field (postgres key)
   * @param {string} salesforceId - case id from salesforce
   * @param {string} s3Key - key for s3 item storage (ie. 'attachment/salesforce/XYZ123')
   * @param {string} name  - file name including extension (for saving later)
   * @param {boolean} active - active attachment or not
   */
  public createEngagementAttachment(
    engagementId: number,
    salesforceId: string,
    s3Key: string,
    name: string,
    active: boolean = true
  ) {
    return this.createAttachment(salesforceId, s3Key, name, active).then(response =>
      this.createEngagementAttachmentMapping(response.data.createAttachment.attachment.id, engagementId, active)
    )
  }

  private createAttachment(salesforceId: string, s3Key: string, fileName: string, active: boolean) {
    return this.apollo.mutate({
      mutation: attachmentMutation,
      variables: {
        active: active,
        fileName: fileName,
        s3Key: s3Key,
        salesforceId: salesforceId,
      },
    })
  }

  private createSupportCaseAttachmentMapping(attachmentId: number, supportCaseId: number, active: boolean) {
    return this.apollo.mutate({
      mutation: supportCaseAttachmentMappingMutation,
      variables: {
        active: active,
        attachmentId: attachmentId,
        supportCaseId: supportCaseId,
      },
    })
  }

  private createEngagementAttachmentMapping(attachmentId: number, engagementId: number, active: boolean) {
    return this.apollo.mutate({
      mutation: engagementAttachmentMappingMutation,
      variables: {
        active: active,
        attachmentId: attachmentId,
        engagementId: engagementId,
      },
    })
  }

  public createCaseComment(
    supportCaseId: string,
    salesforceId: string,
    commentText: string,
    salesforceUserId: string,
    active: boolean = true
  ) {
    return this.apollo
      .mutate({
        mutation: createCaseCommentMutation,
        variables: {
          active: active,
          commentBody: commentText,
          creator: salesforceUserId,
          salesforceId: salesforceId,
          supportCaseId: supportCaseId,
        },
      })
      .then((response: FetchResult<schema.Mutation>) => response.data!.createCaseComment!.caseComment)
  }
}
