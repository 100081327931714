import _ from "lodash"
import Vue from "vue"
import Vuex from "vuex"

// vuex modules
import statustracker from "./modules/statustracker"
import returns from "./modules/returns"
import user from "./modules/user"
import storage from "./modules/storage"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    storage,
    statustracker,
    returns,
    user,
  },
})

export default store
