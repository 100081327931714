export const primary = {
  "050": "#e2f6ff",
  "100": "#b4e7fd",
  "300": "#52c8fb",
  "500": "#08b0fa",
  "700": "#028ed7",
  "900": "#005da1",
  "999": "#00306c",
}

export const secondary = {
  "050": "#fff3e0",
  "100": "#ffe0b2",
  "300": "#ffb74d",
  "500": "#ff9800",
  "700": "#f57c00",
  "900": "#e65100",
  "999": "#ac1900",
}

export const accent = {
  "050": "#fff3e0",
  "100": "#ffe0b2",
  "300": "#ffb74d",
  "500": "#ff9800",
  "700": "#f57c00",
  "900": "#e65100",
  "999": "#ac1900",
}

export const error = {
  "050": "#fae0e0",
  "100": "#ffcabb",
  "300": "#ff8162",
  "500": "#ff3c1e",
  "700": "#fb2c13",
  "900": "#d50000",
  "999": "#9b0000",
}

export const success = {
  "050": "#e4fce7",
  "100": "#bdecc4",
  "300": "#57d374",
  "500": "#00bd2e",
  "700": "#009b14",
  "900": "#006a00",
  "999": "#003d00",
}

export const warning = {
  "050": "#fef7e5",
  "100": "#fff9c4",
  "300": "#fff176",
  "500": "#ffeb3b",
  "700": "#fbc02d",
  "900": "#c49000",
  "999": "#8f6300",
}

export const info = {
  "050": "#e0f2f4",
  "100": "#b1eaf3",
  "300": "#4ccde2",
  "500": "#00b8d4",
  "700": "#0093a7",
  "900": "#005d63",
  "999": "#003339",
}

export const neutral = {
  "100": "#f5f5f5",
  "200": "#ebebeb",
  "300": "#e0e0e0",
  "400": "#cfcfcf",
  "600": "#9e9e9e",
  "700": "#757575",
  "800": "#4f4f4f",
  "900": "#212121",
}

export const theme = {
  primary: primary["900"],
  secondary: secondary["700"],
  accent: accent["700"],
  error: error["900"],
  success: success["700"],
  warning: warning["700"],
  info: info["500"],
}
