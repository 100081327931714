import "core-js/stable"
import "regenerator-runtime/runtime"
import "unfetch/polyfill"

import Vue from "vue"
import Vuei18n from "vue-i18n"
import App from "@/App.vue"
import store from "@/store"
import router from "@/router"
import messages from "@/translations"

import "@/plugins/events"
import "@/plugins/auth0"
import "@/plugins/axios"
import "@/plugins/i18n"
import "@/plugins/filters"
import "@/plugins/vuetify"
import "@/plugins/moment"
import "@/plugins/ag-grid"
import "@/plugins/transmit"
import "@/plugins/raven"
import "@/plugins/clipboard"
import "@/plugins/lodash"
import "@/plugins/detect-browser"
import "@/plugins/infinite-scroll"
import "@/plugins/vuetify-google-autocomplete"
import "@/plugins/quill"
import "@/plugins/shortkey"
import "@/plugins/async-computed"
import "@/plugins/keen"

import { createProvider } from "@/plugins/apollo"
import "@mdi/font/css/materialdesignicons.min.css"

import "@/helpers/filters"

Vue.config.productionTip = false

const apolloProvider = createProvider({ router })
const i18n = new Vuei18n({
  locale: "en",
  fallbackLocale: "en",
  silentTranslationWarn: true,
  messages,
})
const app = {
  router,
  store,
  apolloProvider,
  i18n,
  render: (h: Function) => h(App),
}

Object.defineProperty(Vue.prototype, "$locale", {
  get: () => {
    return app.i18n.locale
  },
  set: locale => {
    app.i18n.locale = locale
  },
})
new Vue(app).$mount("#app")
