import Vue from "vue"
import { createDecorator, VueDecorator } from "vue-class-component"
import VueAsyncComputed, { IAsyncComputedProperty } from "vue-async-computed"

Vue.use(VueAsyncComputed)

/**
 * Defines logic for a `@AsyncComputed` decorator in `vue-class-component`.
 *
 * @export
 * @template T
 * @param {IAsyncComputedProperty<T>} [computedOptions] The AsyncComputed property options
 * @returns {VueDecorator} The decorator function
 */
export function AsyncComputed<T>(computedOptions?: IAsyncComputedProperty<T>): VueDecorator {
  return createDecorator((options: any, key: string) => {
    options.asyncComputed = options.asyncComputed || {}
    const method = options.methods![key]
    options.asyncComputed[key] = <IAsyncComputedProperty<T>>{
      get: method,
      ...computedOptions,
    }
    delete options.methods![key]
  })
}
