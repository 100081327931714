import _ from "lodash"
import { S3 } from "aws-sdk"
import axios from "axios"
import FileSaver from "file-saver"
import { S3PublicImageSize } from "./types"

/**
 * A S3 service for basic intermediary file upload / download.
 *
 * @export
 * @class S3Service
 * @implements {IS3Service}
 */
export default class S3Service {
  /**
   * The S3 service connection.
   *
   * @type {S3}
   * @memberof S3Service
   */
  private connection: S3

  /**
   * Creates an instance of S3Service.
   * @memberof S3Service
   */
  constructor() {
    this.connection = new S3({
      accessKeyId: process.env.VUE_APP_BUCKETEER_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.VUE_APP_BUCKETEER_AWS_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_BUCKETEER_AWS_REGION,
    })
  }

  /**
   * Get a `public` image url.
   *
   * @param {string} type The type categorization of the image to get
   * @param {number} id The type id of the image to get
   * @param {S3PublicImageSize} size The desired size of the image to get
   * @returns {string} The signed url of the image
   * @memberof S3Service
   */
  getImageUrl(type: string, id: number, size: S3PublicImageSize): string {
    return this.connection.getSignedUrl("getObject", {
      Key: `/public/img/${type}/${id}/${size}.png`,
      Bucket: process.env.VUE_APP_BUCKETEER_BUCKET_NAME,
    })
  }

  /**
   * Get the `public` image url for a product group item.
   *
   * @param {number} id The id of the product group item
   * @param {S3PublicImageSize} size The size of the image to get
   * @returns {string} The url of the product group item
   * @memberof S3Service
   */
  getProductGroupImageUrl(id: number, size: S3PublicImageSize): string {
    return this.getImageUrl("product_groups", id, size)
  }

  /**
   * Get the signed url for a given object key.
   *
   * @param {string} key The key of the object to get the signed url for
   * @returns {string} The signed url
   * @memberof S3Service
   */
  getSignedUrl(key: string): string {
    return this.connection.getSignedUrl("getObject", {
      Key: key,
      Bucket: process.env.VUE_APP_BUCKETEER_BUCKET_NAME,
    })
  }

  /**
   * Download an object from s3 storage to the client system.
   *
   * @param {string} key The key of the object to download.
   * @param {string} fileName The filename to use for saving the object
   * @returns {Promise<string>} The filename used for saving the object
   * @memberof S3Service
   */
  async downloadObject(key: string, fileName: string): Promise<string> {
    const signedUrl = this.getSignedUrl(key)
    const response = await axios.get(signedUrl, { responseType: "arraybuffer" })
    if (response.status !== 200) {
      throw new Error(`request for ${signedUrl} failed with status ${response.status}`)
    }
    FileSaver.saveAs(new Blob([response.data]), fileName)
    return fileName
  }

  /**
   * Upload an object to s3 storage
   *
   * @param {File} file - file object to upload
   * @param {string} key - s3 key of where to put object
   */
  public uploadFile(file: File, key: string): Promise<any> {
    const params = {
      Bucket: process.env.VUE_APP_BUCKETEER_BUCKET_NAME,
      ContentType: "text/plain",
      Key: key,
    }

    return axios.put(this.connection.getSignedUrl("putObject", params), file, {
      headers: { "Content-Type": "text/plain" },
    })
  }
}
