import _ from "lodash"
import Cookies from "js-cookie"
import { IStorageMethod, IStorageMethods } from "./types"

const store = require("@/store")

// the available storage types for the storage service
export const STORAGE_TYPES = Object.freeze({
  localStorage: "localStorage",
  sessionStorage: "sessionStorage",
  cookies: "cookies",
  memory: "memory",
})
// the default preference is to use localStorage for all storage
// falls back to transient session storage (and memory which utilizes browser runtime)
const DEFAULT_PREFERENCE = [
  STORAGE_TYPES.localStorage,
  STORAGE_TYPES.sessionStorage,
  STORAGE_TYPES.cookies,
  STORAGE_TYPES.memory,
]

/**
 * A generic interface for application storage.
 *
 * @export
 * @class StorageService
 * @author Stephen Bunn
 */
export class StorageService {
  preference: Array<string>
  storageMethods: IStorageMethods
  action: IStorageMethod
  store: any

  constructor(preference: Array<string> = DEFAULT_PREFERENCE) {
    if (!_.isArray(preference)) {
      throw new Error(`Storage preference must be an Array, received ${preference}`)
    }
    let validStorageTypes = 0
    _.forEach(preference, pref => {
      if (!_.has(STORAGE_TYPES, pref)) {
        console.warn(`No such storage type '${pref}' exists`)
      } else {
        validStorageTypes++
      }
    })
    if (validStorageTypes <= 0) {
      throw new Error(`No valid storage types declared in preference, received ${preference}`)
    }
    this.preference = preference || DEFAULT_PREFERENCE
    this.store = store
    this.storageMethods = {
      localStorage: {
        name: "localStorage",
        available: this._allowsLocalStorage(),
        get: key => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value),
        remove: key => localStorage.removeItem(key),
        has: key => {
          try {
            localStorage.getItem(key)
          } catch (exc) {
            return false
          }
          return true
        },
        clear: () => localStorage.clear(),
      },
      sessionStorage: {
        name: "sessionStorage",
        available: this._allowsSessionStorage(),
        get: key => sessionStorage.getItem(key),
        set: (key, value) => sessionStorage.setItem(key, value),
        remove: key => sessionStorage.removeItem(key),
        has: key => {
          try {
            sessionStorage.getItem(key)
          } catch (exc) {
            return false
          }
          return true
        },
        clear: () => sessionStorage.clear(),
      },
      cookies: {
        name: "cookies",
        available: this._allowsCookies(),
        get: key => Cookies.get(key) || null,
        set: (key, value) => Cookies.set(key, value),
        remove: key => Cookies.remove(key),
        has: key => !_.isNil(Cookies.get(key)),
        clear: () => {
          _.forEach(Cookies.get(), key => {
            Cookies.remove(key)
          })
        },
      },
      memory: {
        name: "memory",
        available: true, // NOTE: always available, but not recommended to use
        get: key => this.store.getters["storage/get"](key),
        set: (key, value) => this.store.commit("storage/set", { [key]: value }),
        remove: key => this.store.commit("storage/remove", key),
        has: key => this.store.getters["storage/has"](key),
        clear: () => this.store.commit("storage/clear"),
      },
    }

    // define appropriate storage method given preference and available methods
    this.action = this.storageMethods.memory
    for (let idx = 0; idx < this.preference.length; idx++) {
      const methodName = this.preference[idx]
      if (_.has(this.storageMethods, methodName)) {
        const method = _.get(this.storageMethods, methodName)
        if (method.available) {
          this.action = method
          break
        }
      } else {
        console.warn(`No such storage method ${methodName}, skipping to next preference`)
      }
    }

    if (_.isNil(this.action)) {
      throw new Error(`No storage method available from preference ${this.preference}`)
    }
  }

  /**
   * Determines if local storage is available in the current browser
   *
   * @returns True if local storage is available, otherwise False
   * @memberof StorageService
   */
  _allowsLocalStorage(): boolean {
    const testingText = "localStorageTest"
    try {
      localStorage.setItem(testingText, testingText)
      localStorage.removeItem(testingText)
    } catch (exc) {
      return false
    }
    return true
  }

  /**
   * Determines if session storage is available in the current browser
   *
   * @returns True if session storage is available, otherwise False
   * @memberof StorageService
   */
  _allowsSessionStorage(): boolean {
    const testingText = "sessionStorageTest"
    try {
      sessionStorage.setItem(testingText, testingText)
      sessionStorage.removeItem(testingText)
    } catch (exc) {
      return false
    }
    return true
  }

  _allowsCookies(): boolean {
    return navigator.cookieEnabled
  }

  /**
   * Get the value of the specified key
   *
   * @param {string} key The key to retrieve the value of
   * @returns The value of the key if it exists, otherwise undefined
   * @memberof StorageService
   */
  get(key: string): string | null {
    if (this.has(key)) {
      return this.action.get(key)
    }
    return null
  }

  /**
   * Sets the value of a given key
   *
   * @param {string} key The key to set the value of
   * @param {string} value The value to set
   * @memberof StorageService
   */
  set(key: string, value: string): void {
    this.action.set(key, value)
  }

  /**
   * Removes the key value pair from storage
   *
   * @param {string} key The key to remove from storage
   * @memberof StorageService
   */
  remove(key: string): void {
    this.action.remove(key)
  }

  /**
   * Determines if a given key exists in storage
   *
   * @param {string} key The key to check
   * @returns True if the key exists in storage, otherwise False
   * @memberof StorageService
   */
  has(key: string): boolean {
    return this.action.has(key)
  }

  /**
   * Clears storage
   *
   * @memberof StorageService
   */
  clear(): void {
    this.action.clear()
  }
}
