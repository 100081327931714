import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"

import "@/assets/styles/vuetify.styl"
const { theme } = require("@/assets/styles/metry/helpers/js/colors")

Vue.use(Vuetify, {
  options: {
    customProperties: false,
  },
  theme,
})
