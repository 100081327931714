import _ from "lodash"
import { Module, VuexModule, Mutation } from "vuex-module-decorators"
import { IStorageState, IStorage } from "./types"

/**
 * The storage store module.
 *
 * @export
 * @class StorageModule
 * @extends {VuexModule<IStorageState>}
 */
@Module({ namespaced: true })
export default class StorageModule extends VuexModule<IStorageState> {
  // the emulated storage object
  storage: IStorage = {}

  /**
   * Check if a given key exists in the storage.
   *
   * @readonly
   * @type {Function}
   * @memberof StorageModule
   */
  get has(): Function {
    return (key: string): boolean => _.has(this.storage, key)
  }

  /**
   * Get the value from a given key in the storage.
   *
   * @readonly
   * @type {Function}
   * @memberof StorageModule
   */
  get get(): Function {
    return (key: string): any => _.get(this.storage, key, null)
  }

  /**
   * Clear the current storage.
   *
   * @memberof StorageModule
   */
  @Mutation
  clear() {
    this.storage = {}
  }

  /**
   * Set a key value pair in the storage.
   *
   * @param {object} payload The object of key value pairs to add to the storage
   * @memberof StorageModule
   */
  @Mutation
  set(payload: object) {
    this.storage = _.merge(this.storage, payload)
  }

  /**
   * Remove a given key value pair from the storage.
   *
   * @param {string} key The key of the pair to remove
   * @memberof StorageModule
   */
  @Mutation
  remove(key: string) {
    this.storage = _.omit(this.storage, [key])
  }
}
