const messages = {
  en: {
    language: "Language",
    dialog: {
      close: "Close",
    },
    route: {
      home: "Home",
      return: "Returns",
      statustracker: "Status Tracker",
      order: "Ordering",
      dashboard: "Dashboard",
      deployment: "Deployment & Services",
      support: "Support",
    },
  },
  es: {
    language: "Idioma",
    dialog: {
      close: "Cerrar",
    },
    route: {
      home: "Inicio",
      return: "Regresos",
      statustracker: "Proyectos",
      order: "Ordenando",
      dashboard: "Tablero",
      deployment: "Despliegue y Servicios",
      support: "Apoya",
    },
  },
  ja: {
    language: "言語",
    dialog: {
      close: "閉じる",
    },
    route: {
      home: "自宅",
      return: "返品",
      statustracker: "企画",
      order: "注文",
      dashboard: "計器盤",
      deployment: "配備",
      support: "サポート",
    },
  },
}

export default messages
