import { cx } from "@/types"
import * as schema from "@/graphql/__schema"
import _ from "lodash"

export default class AppUtility {
  static BUILD_WORKFLOW(workflow: schema.Workflow): cx.App.Workflow {
    return {
      _active: workflow._active,
      id: workflow.id,
      name: workflow.name,
      description: workflow.description!,
      order: workflow.order!,
      isOptional: workflow.isOptional,
      statuses: _.map(workflow.statusesByWorkflowId.nodes, status => {
        return AppUtility.BUILD_STATUS(status)
      }),
    }
  }
  static BUILD_STATUS(status: schema.Status): cx.App.Status {
    return {
      _active: status._active,
      id: status.id,
      name: status.name,
      showAlert: status.showAlert,
      description: status.description!,
      order: status.order!,
      isOptional: status.isOptional,
      assignedTo: status.assignedTo!,
      isInternal: status.isInternal!,
    }
  }
  static BUILD_STATE(state: schema.State): cx.App.State {
    return {
      name: state.name,
      description: state.description || null,
    }
  }
  static BUILD_USER(user: schema.User): cx.App.User {
    return {
      id: user.id,
      email: user.email,
      username: user.username,
      title: user.title || null,
      lastName: user.lastName || null,
      firstName: user.firstName || null,
      fullName: user.fullName,
      isEmployee: user.isEmployee,
      phoneNumber: user.phoneNumber || null,
      salesforceId: user.salesforceId || null,
    }
  }
}
