











import { Vue, Component, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "trunquee",
})
export default class Trunquee extends Vue {
  displayText: string = ""
  interval: any = false
  $text: any
  @Prop({ type: String, required: true }) readonly text!: string
  @Prop({ type: Number, default: 85 }) readonly speed!: number
  @Prop({ type: String, default: "default" }) readonly cursor!: string

  get styles(): any {
    return {
      cursor: this.interval ? this.cursor : "default",
    }
  }

  @Watch("text", { immediate: true })
  textHandler(): void {
    if (!this.interval) this.resetText()
  }

  onEnter(): void {
    if (this.interval || !this.isTruncated()) return
    this.interval = setInterval(() => {
      if (!this.isTruncated()) return clearInterval(this.interval)
      this.displayText = this.displayText.slice(1)
      while (this.displayText.charAt(0) === " ") {
        this.displayText = this.displayText.slice(1)
      }
    }, this.speed)
  }

  onLeave(): void {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = false
    }
    this.resetText()
  }

  isTruncated(): boolean {
    return this.getTruncatedDistance() > 0
  }

  getTruncatedDistance(): number {
    return this.$text.scrollWidth - this.$text.clientWidth
  }

  resetText(): void {
    this.displayText = this.text
  }

  mounted(): void {
    this.$text = this.$refs.text
  }
}
